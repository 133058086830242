<template>
  <section>
    <mu-mega-icon :icon="serviceIcon"
      width="12%"
      height="12%"
      fill-color="red"
      class="icon" />
    <h1>{{ header }}</h1>
    <p>{{ content }}</p>
  </section>
</template>

<script>
export default {
  name: 'VendorIntroduction',
  props: {
    serviceIcon: {
      type: String,
      default: null,
    },
    header: {
      type: String,
      required: true,
      default: () => 'Welcome!',
    },
    content: {
      type: String,
      required: true,
      default: () => '',
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 90;
  font-size: 4rem;
}

p {
  margin-top: 5rem;
  font-size: 2rem;
  line-height: 3rem;
}
</style>
