<template>
  <section>
    <!-- Temp Password -->
    <div v-if="currentView === TMP_PASSWORD_VIEW"
      :key="currentView">
      <p class="password-info">
        <strong>{{ $t(tmpPasswordInfo.intro) }}</strong>
        <span>{{ $t(tmpPasswordInfo.content) }}</span>
      </p>
      <el-form ref="tempPasswordForm"
        v-loading="isLoading"
        :model="tempPasswordForm"
        data-name="temp-password-form"
        :rules="tempPasswordRules"
        label-position="top"
        @submit.native.prevent="login">
        <el-form-item :label="$t('general.email')"
          prop="username">
          <el-input v-model="tempPasswordForm.username"
            name="username"
            type="email"
            autocomplete="off"
            :placeholder="$t('general.email')" />
        </el-form-item>

        <el-form-item :label="$t('authentication.temp-password')"
          prop="tempPassword"
          class="temp-password">
          <el-input v-model="tempPasswordForm.tempPassword"
            :placeholder="$t('authentication.temp-password')"
            name="tempPassword"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="off">
            <span slot="suffix"
              class="cursor-pointer">
              <i v-if="showPassword"
                class="fa fa-eye mr-1"
                @click="showPassword = !showPassword" />
              <i v-else
                class="fa fa-eye-slash mr-1"
                @click="showPassword = !showPassword" />
            </span>
          </el-input>
        </el-form-item>

        <!-- login -->
        <el-button name="login"
          type="primary"
          native-type="“submit”"
          class="full-width">
          {{ $t('authentication.log-in') }}
        </el-button>

        <i18n path="general.login-trouble"
          tag="p"
          class="text-align-center">
          <template #link>
            <a href="https://www.megaport.com/contact/support/"
              target="_blank">{{ $t('general.get-help') }}</a>
          </template>
        </i18n>

        <p class="disclaimer">
          {{ $t('general.site-disclaimer') }}
        </p>
      </el-form>
    </div>

    <!-- Password Reset -->
    <div v-if="currentView === PASSWORD_RESET_VIEW"
      :key="currentView">
      <p>
        <strong>{{ $t(newPasswordInfo.intro) }}</strong>
        <span>{{ $t(newPasswordInfo.content) }}</span>
      </p>
      <el-form ref="passwordResetForm"
        v-loading="isLoading"
        :model="passwordResetForm"
        data-name="password-reset-form"
        :rules="passwordResetRules"
        label-position="top"
        @submit.native.prevent="submit">
        <el-form-item :label="$t('authentication.new-password')"
          prop="newPassword">
          <el-input v-model="passwordResetForm.newPassword"
            :placeholder="$t('authentication.new-password')"
            name="newPassword"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="off">
            <span slot="suffix"
              class="cursor-pointer">
              <i v-if="showPassword"
                class="fa fa-eye mr-1"
                @click="showPassword = !showPassword" />
              <i v-else
                class="fa fa-eye-slash mr-1"
                @click="showPassword = !showPassword" />
            </span>
            <template #append>
              <el-popover placement="top"
                :title="$t('authentication.password-must-include')"
                width="300"
                trigger="hover">
                <div class="text-align-left">
                  <ul class="pl-2">
                    <li>{{ $t('authentication.at-least-8-char') }}</li>
                    <li>{{ $t('authentication.at-least-one-number') }}</li>
                    <li>{{ $t('authentication.at-least-one-symbol') }}</li>
                    <li>{{ $t('authentication.at-least-one-lower-case-letter') }}</li>
                    <li>{{ $t('authentication.at-least-one-upper-case-letter') }}</li>
                  </ul>
                </div>
                <span slot="reference">
                  <i class="el-icon-info color-info"
                    aria-hidden="true" />
                </span>
              </el-popover>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('authentication.confirm-password')"
          prop="confirmPassword">
          <el-input v-model="passwordResetForm.confirmPassword"
            :placeholder="$t('authentication.confirm-password')"
            name="confirmPassword"
            autocomplete="off"
            type="password" />
        </el-form-item>

        <!-- submit -->
        <el-button name="submit"
          type="primary"
          native-type="“submit”"
          class="full-width">
          {{ $t('authentication.submit') }}
        </el-button>
      </el-form>

      <p class="disclaimer">
        {{ $t('general.site-disclaimer') }}
      </p>
    </div>

    <!-- Password changed -->
    <div v-if="currentView === PASSWORD_CHANGED_VIEW">
      <p data-name="password-reset-success">
        <strong>{{ $t(passwordChangedInfo.intro) }}</strong>
      </p>
      <p>{{ $t(passwordChangedInfo.content) }}</p>
      <p>{{ $t('general.learn-megaport') }}</p>
      <p class="font-weight-400">
        <a href="https://docs.megaport.com/"
          target="_blank">{{ $t('general.visit-documentation') }}</a>
      </p>
      <p class="font-weight-400 mb-0">
        <a @click="routeToLogin">{{ $t('general.visit-portal') }}</a>
      </p>
    </div>
  </section>
</template>

<script>
import { validatePassword } from '@/validators.js'
import { showModalAlert } from './AuthHelpers.js'
import { passStrength } from '@/utils/passwordStrength.js'

const TMP_PASSWORD_VIEW = 'temp-password'
const PASSWORD_RESET_VIEW = 'password-reset'
const PASSWORD_CHANGED_VIEW = 'password-changed'

export default {
  name: 'VendorPasswordForm',
  props: {
    icon: {
      type: String,
      required: false,
      default: null,
    },
    tmpPasswordInfo: {
      type: Object,
      required: true,
    },
    newPasswordInfo: {
      type: Object,
      required: true,
    },
    passwordChangedInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      showPassword: false,
      tempPasswordForm: {
        username: '',
        tempPassword: '',
      },
      tempPasswordRules: {
        username: { required: true, message: this.$t('validations.required', { thing: 'Email' }), trigger: 'blur' },
        tempPassword: { required: true, message: this.$t('validations.required', { thing: 'Temporary password' }), trigger: 'blur' },
      },
      passwordResetForm: {
        newPassword: '',
        confirmPassword: '',
      },
      passwordResetRules: {
        newPassword: [
          { required: true, message: this.$t('validations.required', { thing: 'New password' }), trigger: 'blur' },
          { validator: this.validateNewPassword, trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, message: this.$t('validations.required', { thing: 'Confirm password' }), trigger: 'blur' },
          { validator: this.validateConfirmPassword, trigger: 'blur' },
        ],
      },
      currentView: TMP_PASSWORD_VIEW,
      TMP_PASSWORD_VIEW,
      PASSWORD_RESET_VIEW,
      PASSWORD_CHANGED_VIEW,
    }
  },
  created() {
    // prefill the email field from URL
    if (this.$route.query.email) {
      this.tempPasswordForm.username = this.$route.query.email
    }
  },
  methods: {
    /**
     * Validate password strength
     * @param {Object} rule
     * @param {string} value
     * @param {Function} callback
     */
    validateNewPassword(rule, value, callback) {
      const passwordAnalysis = passStrength(value)
      validatePassword(rule, value, callback, passwordAnalysis)
    },
    /**
     * Validate password and confirm password fields match
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    validateConfirmPassword(_rule, value, callback) {
      if (value !== this.passwordResetForm.newPassword) {
        callback(window.mpApp.$t('validations.confirm-password-mismatch'))
      } else {
        callback()
      }
    },
    login() {
      this.$refs['tempPasswordForm'].validate(valid => {
        if (!valid) {
          return false
        }

        const { username, tempPassword: password } = this.tempPasswordForm
        const payload = {
          username,
          password,
        }
        this.isLoading = true
        const showLoginError = () => showModalAlert(
          this.$t('authentication.login-error-msg'),
          this.$t('authentication.failed'),
          'error',
          true
        )

        this.$store
          .dispatch('Auth/login', { payload, setLoginState: false })
          .then(() => showLoginError())
          .catch(err => {
            if (!err.handled) {
              if (err.status === 401) {
                if (err.data?.data === 'RP') {
                  localStorage.removeItem('_token')
                  this.currentView = PASSWORD_RESET_VIEW

                } else {
                  showLoginError()
                }
              } else {
                const errMsg = err.data?.message || this.$t('general.unknown-error')
                showModalAlert(errMsg, this.$t('authentication.failed'), 'error')
              }
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      })
    },
    submit() {
      this.$refs['passwordResetForm'].validate(valid => {
        if (!valid) {
          return false
        }

        const { username, tempPassword: password } = this.tempPasswordForm
        const { newPassword: resetPassword } = this.passwordResetForm
        const payload = {
          username,
          password,
          resetPassword,
        }
        this.isLoading = true

        this.$store
          .dispatch('Auth/login', { payload, setLoginState: false })
          .then(() => {
            this.currentView = PASSWORD_CHANGED_VIEW
          })
          .catch(err => {
            if (!err.handled) {
              const errMsg = err.data?.message || this.$t('general.unknown-error')
              showModalAlert(errMsg, this.$t('authentication.failed'), 'error')
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      })
    },
    routeToLogin() {
      this.$router.push('/login', () => { /* empty function is intentional */ })
    },
  },
}
</script>

<style lang="scss" scoped>
.password-info {
  line-height: 2.2rem;
}

a {
  cursor: pointer;
  text-decoration: underline;
}

button {
  margin: 1rem 0 0 0;
}

.disclaimer {
  margin-top: 3rem;
  font-size: 1.2rem;
}

// Fix password eye icon
::v-deep .el-input__suffix {
  top: 10px;
}
::v-deep .temp-password .el-input__suffix {
  top: 0;
}
</style>
