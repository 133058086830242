<template>
  <div class="flex-row-centered full-height flex-wrap content-container">
    <div class="content-column hidden-sm-and-down intro-section">
      <div>
        <div class="flex-column intro-container">
          <vendor-introduction :service-icon="vendorConfig.introduction.icon"
            :header="vendorConfig.introduction.header"
            :content="vendorConfig.introduction.content" />
        </div>
      </div>
    </div>

    <div class="content-column password-section">
      <div>
        <div class="password-card">
          <el-card shadow="never">
            <el-row>
              <el-col :span="24"
                class="img-container megaport">
                <div class="logo">
                  <img src="@/assets/images/megaport-logos/Megaport-Logo-Web-72dpi-RGB.png"
                    class="mp-img"
                    :alt="$t('images.megaport-logo')">
                </div>
              </el-col>
            </el-row>
            <vendor-password-form :tmp-password-info="vendorConfig.form.tmpPasswordInfo"
              :new-password-info="vendorConfig.form.newPasswordInfo"
              :password-changed-info="vendorConfig.form.passwordChangedInfo" />
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VendorIntroduction from './VendorIntroduction.vue'
import VendorPasswordForm from './VendorPasswordForm.vue'
import { G_PRODUCT_TYPE_MVE, CISCO_VENDOR } from '@/Globals.js'

export default {
  name: 'VendorOnboarding',
  components: {
    'vendor-introduction': VendorIntroduction,
    'vendor-password-form': VendorPasswordForm,
  },
  inject: ['disabledFeatures'],
  data() {
    return {
      vendors: {
        cisco: {
          name: CISCO_VENDOR,
          introduction: {
            icon: G_PRODUCT_TYPE_MVE,
            header: this.$t('authentication.vendor-onboarding.cisco.intro-header'),
            content: this.$t('authentication.vendor-onboarding.cisco.intro-content'),
          },
          form: {
            tmpPasswordInfo: {
              intro: this.$t('authentication.vendor-onboarding.cisco.temp-password-intro'),
              content: this.$t('authentication.vendor-onboarding.cisco.password-info'),
            },
            newPasswordInfo: {
              intro: this.$t('authentication.vendor-onboarding.cisco.new-password-intro'),
              content: this.$t('authentication.vendor-onboarding.cisco.password-info'),
            },
            passwordChangedInfo: {
              intro: this.$t('authentication.vendor-onboarding.cisco.password-changed-intro'),
              content: this.$t('authentication.vendor-onboarding.cisco.password-changed-info'),
            },
          },
        },
      },
      vendorConfig: null,
    }
  },
  created() {
    this.vendorConfig = this.vendors[this.$route.params.vendor.toLowerCase()]
  },
}
</script>

<style lang="scss" scoped>
.content-container {
  width: 80%;
  margin: auto;
}
.content-column {
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  width: min-content;
  min-width: 400px;
  @media (max-width: var(--md) - 1) {
    width: 100%;
  }

  &.intro-section {
    width: 40%;
    margin: auto;

    .intro-container {
      padding: 0 10rem;
    }
  }

  &.password-section {
    width: 45%;
    margin: auto;

    .password-card {
      width: 465px;
      margin: auto;
      margin-bottom: 50px;

      .img-container {
        margin-bottom: 1rem;

        .logo {
          position: relative;
          margin: auto;
          margin-top: 20px;
          margin-bottom: 2.5rem;
          text-align: center;

          .vendor-img {
            height: 50px;
          }

          .mp-img {
            height: 50px;
          }
        }
      }
    }
  }
}
.content-column > div {
  flex: 1 1 auto;
}
</style>
